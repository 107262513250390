<template>
    <div class="middle_page" style="margin-top:0px !important;">
        <div v-for="(item, index) in list" :key="index">
            <item-model v-if="index < 2" :itemInfo="item" @goDownload="goDownload"></item-model>
        </div>
        <div class="litely">
            <div class="litely_info" @click="goDownload('store')">
                <img class="left" src="https://media.litelyapp.com/web/20230221/litely_logo.webp" />
                <div class="middle">
                    <img src="https://media.litelyapp.com/web/20230221/litely_title.webp" class="top" />
                    <div class="bottom">
                        Fast with your personal Coach
                    </div>
                </div>
                <img src="https://media.litelyapp.com/web/20230221/ratings.webp" class="right">
            </div>
            <div class="comment">
                <div class="comment_item">
                    <div class="comment_item_title">
                        I've lost some weight already with it.
                    </div>
                    <img src="https://media.litelyapp.com/web/20230221/star_rating.webp" class="star_rating">
                    <div class="comment_item_describe">
                        The fasting timer is great for getting you started, I paid for the personal plan so I could get
                        personalized guide and join the challenges. I've lost some weight already with it.
                    </div>
                </div>
                <div class="comment_item">
                    <div class="comment_item_title">I love this app! </div>
                    <img src="https://media.litelyapp.com/web/20230221/star_rating.webp" class="star_rating">
                    <div class="comment_item_describe">
                        Litely keeps me on track all day and I actually remember to drink water!
                    </div>
                </div>
            </div>
        </div>
        <item-model :itemInfo="list[2]" :end="true" @goDownload="goDownload"></item-model>
        <div class="float_shadow"> </div>
        <div class="float_button" @click="goDownload('button')">
            Personalize My Plan
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import ItemModel from '@/components/item_model.vue'
import { logFirebaseEvent } from '@/assets/log/log_manager.js'
export default {
    name: 'Index',
    components: { ItemModel },
    data() {
        return {
            targetUrl: '',
            queryMap: new Map(),
            list: [
                {
                    title: 'Hit your goal weight in the fastest way ever',
                    describe: 'With cutting-edge research and extensive expertise in fasting, we are dedicated to helping you achieve your weight loss goal with a personal fasting plan.',
                    img: 'https://media.litelyapp.com/web/20230221/banner1.webp',
                    color: '#FFEFC9',
                    size: 550 / 375,
                },
                {
                    title: 'Lose weight safely and efficiently without diet or exercise',
                    describe: 'No more fussing over diets and exercise - try our personal fasting plan for a simpler weight loss journey!',
                    img: 'https://media.litelyapp.com/web/20230221/banner2.webp',
                    color: '#DDF3E6',
                    size: 295 / 375,
                },
                {
                    title: 'Embark on your fasting journey guided by your personal Coach',
                    describe: 'With a custom-designed fasting plan, your Coach will help you seamlessly integrate fasting into your daily routine. All you need is to follow her guidance to start your journey towards a lighter, happier you.',
                    img: 'https://media.litelyapp.com/web/20230221/banner3.webp',
                    color: '#E8EFFF',
                    size: 654 / 375,
                },
            ],
        }
    },
    created() {
    },
    mounted() {
        // 获取参数，请求后端接口发送url
        this.queryForm();
        // 曝光埋点
        logFirebaseEvent('F_Web_FBAd_Show');
    },
    methods: {
        queryForm() {
            var self = window.location.toString();
            var queryString = self.split("?");
            if (queryString.length > 1) {
                var pairs = queryString[1].split("&");
                for (var i in pairs) {
                    var keyVal = pairs[i].split("=");
                    this.queryMap.set(keyVal[0], keyVal[1]);
                }
            }

            // 拼接url
            this.targetUrl = `https://app.adjust.com/${this.queryMap.get('p0')}?campaign=${this.queryMap.get('p1')}%20%28${this.queryMap.get('p2')}%29&adgroup=${this.queryMap.get('p3')}%20%28${this.queryMap.get('p4')}%29&creative=${this.queryMap.get('p5')}%20%28${this.queryMap.get('p6')}%29&fbclid=${this.queryMap.get('fbclid')}&fbpid=${this.queryMap.get('fbpid')}`;
            // 请求后台
            axios.post('/wapi/download/logs', { content: this.targetUrl });
        },
        goDownload(clickType) {
            let type = '';
            if (clickType == 'img') {
                type = '0';
            } else if (clickType == 'button') {
                type = '1';
            } else if (clickType == 'store') {
                type = '2';
            }
            // 点击埋点
            logFirebaseEvent('F_Web_FBAd_Click', { "type": type });
            window.open(this.targetUrl, '_blank');
        }
    }
}
</script>
<style  lang="less">
html,
body {
    margin: 0;
    padding: 0;
}

.middle_page {
    max-width: 500px;
    margin: 0 auto;
}

.litely {
    padding: 40px 18px;
}

.litely_info {
    display: flex;
    height: 60;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.litely_info .left {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.litely_info .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

.litely_info .middle .top {
    height: 20px;
}

.litely_info .middle .bottom {
    font-size: 14px;
    font-family: 'AvenirNext';
    font-weight: 500;
    color: #777777;
    line-height: 19px;
}

.litely_info .right {
    width: 62px;
    height: 42px;
}


.comment_item {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    padding: 12px 16px 18px;
    text-align: left;
    border-radius: 15px;
    background: #F2F1F6;
}

.comment_item_describe {
    font-size: 13px;
}

.star_rating {
    margin-bottom: 12px;
    margin-left: -4px;
}

.float_button {
    width: 275px;
    height: 58px;
    background: linear-gradient(225deg, #F6AE0E 0%, #E512AC 100%);
    border-radius: 29px;
    font-size: 20px;
    font-family: 'AvenirNext';
    font-weight: 600;
    color: #FFFFFF;
    line-height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
}

.float_shadow {
    position: fixed;
    bottom: 35px;
    left: 50%;
    width: 266px;
    height: 58px;
    background: linear-gradient(225deg, #F6AE0E 0%, #E512AC 100%);
    border-radius: 29px;
    opacity: 0.5;
    filter: blur(7px);
    transform: translateX(-50%);
}
</style>