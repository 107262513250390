<template>
    <div class="itemModel" :style="{ backgroundColor: itemInfo.color, paddingBottom: end ? '100px' : '40px' }">
        <div class="title">
            {{ itemInfo.title }}
        </div>
        <div class="describe">
            {{ itemInfo.describe }}
        </div>
        <div class="banner" @click="goDownload">
            <img :src="itemInfo.img" :style="{ 'height': `calc(${itemInfo.size}px * ${size})` }" />
        </div>
    </div>
</template>
  
<script>

export default {
    props: {
        itemInfo: {
            type: Object,
            default: () => { }
        },
        end: {
            type: Boolean,
            default: () => false,
        }
    },
    name: 'ItemModel',
    data() {
        return {
            size: 0,
        }
    },
    mounted() {
        this.size = Math.min(document.body.clientWidth, 500);
    },
    methods: {
        goDownload() {
            this.$emit('goDownload', 'img')
        },
    }
}
</script>

<style>
.itemModel {
    padding: 60px 0 40px;
}

.title {
    font-size: 28px;
    font-family: 'AvenirNext';
    font-weight: 700;
    color: #484E58;
    line-height: 38px;
    text-align: left;
    margin-bottom: 10px;
    padding: 0 25px;
}

.describe {
    font-size: 22px;
    font-family: 'AvenirNext';
    font-weight: 500;
    color: #666C76;
    text-align: left;
    margin-bottom: 10px;
    line-height: 30px;
    padding: 0 25px;
}

.banner img {
    width: 100%;
    display: block;
}
</style>
  